import { State } from "../types";

interface Payload {
  customerRequestedCall: boolean;
}

export default function setCustomerRequestedCall(
  state: State,
  payload: Payload
): State {
  const { customerRequestedCall } = payload;

  return {
    ...state,
    customerRequestedCall,
  };
}
