import * as React from "react";

import { Button } from "@literati/dj-react";

import useBoxReturn from "boxReturn/useBoxReturn";

/**
 * A button and barcode listener for canceling a box return
 */
export default function CancelBoxReturnButton() {
  const { cancelBoxReturn } = useBoxReturn();

  return (
    <Button onClick={cancelBoxReturn} variant="danger">
      Cancel Box Return
    </Button>
  );
}
