import { useCallback, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import useStation from "hooks/useStation";

import { ContextValue } from "../types";

/**
 * Fetches the last few boxes that were processed at this station
 */
export default function useFetchHistory(context: ContextValue) {
  const { checkForErrors, dispatch, onError } = context;
  const { stateRef: stationStateRef } = useStation();

  const onCompleted = useCallback(
    function onCompleted({ intakeStationHistory: res }) {
      if (checkForErrors(res)) return;
      const { boxReturns } = res;
      dispatch({
        type: "setHistory",
        payload: {
          history: boxReturns,
        },
      });
    },
    [checkForErrors, dispatch]
  );

  const [query] = useLazyQuery(GQL, {
    fetchPolicy: "network-only",
    onCompleted,
    onError,
  });

  const fetchHistory = useCallback(() => {
    const { stationId } = stationStateRef.current;
    const variables = {
      stationId,
    };
    return query({ variables });
  }, [query, stationStateRef]);

  // fetch on load
  useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return context;
}

const GQL = gql`
  query IntakeStationHistory($stationId: String!) {
    intakeStationHistory(stationId: $stationId) {
      success
      errorMessage
      boxReturns {
        boxShipmentId
        intakeStartAt
        stage
      }
    }
  }
`;
