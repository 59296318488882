import * as React from "react";

import { Button } from "@literati/dj-react";

import useBoxReturn from "boxReturn/useBoxReturn";

export default function ReverseReturnBanner() {
  const { boxReturn } = useBoxReturn();

  /**
   * Only display this banner if the box is past the intake stage.
   */
  if (boxReturn?.stage === "intake") return null;

  return (
    <div className="danger-banner">
      <div className="banner-content">
        <div>
          This box was already returned at {boxReturn?.intakeStationId}. To make
          edits you must first reverse the inventory.
        </div>

        <ReverseReturnButton />
      </div>
    </div>
  );
}

/**
 * A button that allows reversal of a box intake
 */
function ReverseReturnButton() {
  const { reverseBoxReturn } = useBoxReturn();

  return (
    <Button size="sm" onClick={reverseBoxReturn}>
      Reverse Inventory
    </Button>
  );
}
