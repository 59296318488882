import { State, SetErrorsPayload } from "../types";

/**
 * overrides errors values
 */
export default function setErrors(
  state: State,
  payload: SetErrorsPayload
): State {
  const { errors } = payload;
  return {
    ...state,
    errors,
  };
}
