import { State, SetBoxReturnPayload } from "../types";

/**
 * Sets up the state for the given box return
 */
export default function setBoxReturn(
  state: State,
  payload: SetBoxReturnPayload
): State {
  let {
    returnedbookSet: returnedBooks,
    intakeNotes,
    isEmpty,
    isUnsealed,
    isReturnToSender,
    customerRequestedCall,
    customerRequestedCancel,
    ...boxReturn
  } = payload.boxReturn;

  // if the box doesn't have intake notes use the state value
  if (!intakeNotes) intakeNotes = state.intakeNotes;

  return {
    ...state,
    boxReturn,
    returnedBooks,
    intakeNotes,
    isEmpty,
    isUnsealed,
    isReturnToSender,
    customerRequestedCall,
    customerRequestedCancel,
  };
}
