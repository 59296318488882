import { useCallback } from "react";

import useCheckForErrors from "global/hooks/useCheckForErrors";
import { ContextValue, ReducerType } from "../types";

export default function useSetErrors(context: ContextValue): ContextValue {
  const { dispatch } = context;

  const setErrors = useCallback(
    function setErrors(errors: string[]) {
      dispatch({
        type: ReducerType.setErrors,
        payload: {
          errors,
        },
      });
    },
    [dispatch]
  );

  // used for handling graphql error messages
  const onError = useCallback(
    (err) => {
      setErrors([err.message]);
    },
    [setErrors]
  );

  const checkForErrors = useCheckForErrors(setErrors);

  return {
    ...context,
    setErrors,
    checkForErrors,
    onError,
  };
}
