import { useCallback } from "react";
import { ReducerType, ContextValue } from "../types";

/**
 * A method for setting the value of activeToteName, which triggers
 * the SwapTote modal to show or hide.
 */
export default function useSetToteModal(context: ContextValue): ContextValue {
  const { dispatch } = context;

  context.setToteModal = useCallback(
    (toteName: string) => {
      dispatch({
        type: ReducerType.setToteModal,
        payload: {
          toteName,
        },
      });
    },
    [dispatch]
  );

  return context;
}
