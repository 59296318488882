import * as React from "react";
import useBoxReturn from "boxReturn/useBoxReturn";
import BreadCrumb from "components/BreadCrumb";

/**
 * A reusable component for rendering a clickable back button
 * in view heros
 */
export default function BoxBreadCrumb() {
  const { boxShipmentId, goBack } = useBoxReturn();
  return <BreadCrumb text={`Box ${boxShipmentId}`} onClick={goBack} />;
}
