import { STORAGE_KEY } from "../constants";
import { State } from "../types";

interface Payload {
  intakeNotes: string;
}
/**
 * This reducer function saves intake notes to a local storage
 * so it doesn't accidentally get removed on refresh.
 */
export default function setNotes(state: State, payload: Payload): State {
  const { intakeNotes } = payload;
  const { boxShipmentId } = state;

  localStorage.setItem(STORAGE_KEY.BOX_NOTES(boxShipmentId), intakeNotes);

  return {
    ...state,
    intakeNotes,
  };
}
