import { State } from "../types";

interface Payload {
  customerRequestedCancel: boolean;
}

export default function setCustomerRequestedCancel(
  state: State,
  payload: Payload
): State {
  const { customerRequestedCancel } = payload;

  return {
    ...state,
    customerRequestedCancel,
  };
}
