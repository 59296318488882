import { useCallback } from "react";
import { ContextValue, ReducerType } from "../types";

export default function useSelectBook(context: ContextValue): ContextValue {
  const { stateRef, dispatch, createReturnedBook } = context;

  const selectBook = useCallback(
    (barcode: string) => {
      return selectBookInternal(
        barcode,
        stateRef,
        dispatch,
        createReturnedBook
      );
    },
    [stateRef, dispatch, createReturnedBook]
  );

  const selectReturnedBook = useCallback(
    (returnedBookId: string) => {
      dispatch({
        type: ReducerType.setCurrentBook,
        payload: {
          currentReturnedBookId: returnedBookId,
        },
      });
    },
    [dispatch]
  );

  const goBack = useCallback(() => {
    dispatch({
      type: ReducerType.goBack,
      payload: {
        currentReturnedBookId: null,
      },
    });
  }, [dispatch]);

  return {
    ...context,
    selectBook,
    goBack,
    selectReturnedBook,
  };
}

export function selectBookInternal(
  barcode: string,
  stateRef,
  dispatch,
  createReturnedBook
) {
  // check if the book exists
  const { returnedBooks } = stateRef.current;
  const existingReturnedBook = returnedBooks.find(
    (rb) => rb.barcode.toLowerCase() === barcode.toLowerCase()
  );
  if (existingReturnedBook) {
    // if it exists, select it
    dispatch({
      type: ReducerType.setCurrentBook,
      payload: {
        currentReturnedBookId: existingReturnedBook.id,
      },
    });
  } else {
    // if it doesn't exist, create it.
    createReturnedBook(barcode);
  }
}
