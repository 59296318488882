import { State } from "../types";

/**
 * Updates the stationId
 */
export default function updateStationId(state: State, payload): State {
  const { stationId } = payload;
  return {
    ...state,
    stationId,
  };
}
