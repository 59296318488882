import { State } from "../types";

interface Payload {
  isReturnToSender: boolean;
}

export default function setIsReturnToSender(
  state: State,
  payload: Payload
): State {
  const { isReturnToSender } = payload;

  return {
    ...state,
    isReturnToSender,
  };
}
