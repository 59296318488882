import { useBarcodeScanner } from "@literati/dj-react";

import useBoxReturn from "boxReturn/useBoxReturn";

export default function useHandleBookScan() {
  const { selectBook } = useBoxReturn();

  useBarcodeScanner({
    types: ["ISBN", "PRODUCT"],
    onScan: selectBook,
  });
}
