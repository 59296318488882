import { useEffect } from "react";

import { ContextValue } from "../types";

/**
 * When the stationId changes, get the totes
 */
export default function useGetTotesEffect(context: ContextValue): ContextValue {
  const { stationId, getTotes } = context;

  useEffect(() => {
    // wait until we have stationId
    if (!stationId) return;
    // get totes for this stationId
    getTotes();
  }, [stationId, getTotes]);

  return context;
}
