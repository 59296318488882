import * as React from "react";
import {
  Navbar,
  Nav,
  Logo,
  NavItem,
  useUser,
  Icon,
  Button,
} from "@literati/dj-react";

import { URLS } from "global/constants";
import useStation from "hooks/useStation";

export default function Navigation() {
  const { stationId } = useStation();

  return (
    <Navbar>
      <Logo />
      <h1
        style={{
          fontSize: "var(--dj-size-font-md)",
          fontWeight: "var(--dj-font-weight-bold)",
        }}
      >
        Returns: {stationId || "Set Station"}
      </h1>
      <Nav>
        <NavItem href={URLS.SEARCH}>Intake</NavItem>
        <NavItem href={URLS.INIT_STATION}>Change Station</NavItem>
      </Nav>
      <Auth />
    </Navbar>
  );
}

function Auth() {
  const { user, logInUser, logOutUser } = useUser();
  if (!user.is_authenticated) return <div onClick={logInUser}>Login</div>;
  return (
    <div
      onClick={logOutUser}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      <>{user.email} </>
      <Icon name="x" style={{ width: "20px" }} />
    </div>
  );
}
