import { gql } from "@apollo/client";

export const RETURNED_BOOK_BASE = gql`
  fragment ReturnedBookBase on ReturnedBook {
    id
    barcode
    intakeType
    toteName
    barcode
    book {
      id
      title
    }
    damages {
      severity
      category
      notes
    }
  }
`;

export const BOX_RETURN_BASE = gql`
  fragment BoxReturnBase on BoxReturn {
    boxShipmentId
    boxShipment {
      personalizationName
    }
    stage
    intakeStationId
    intakeNotes
    specialReturnProcessing
    isEmpty
    isUnsealed
    isReturnToSender
    customerRequestedCall
    customerRequestedCancel
    returnedbookSet {
      ...ReturnedBookBase
    }
  }
  ${RETURNED_BOOK_BASE}
`;
