import { useCallback } from "react";
import { ContextValue, ReducerType } from "../types";

/**
 * A method for setting the active damage category
 * for the BookView.
 */
export default function useSetDamageCategory(
  context: ContextValue
): ContextValue {
  const { dispatch } = context;

  const setDamageCategory = useCallback(
    (category: string) => {
      dispatch({
        type: ReducerType.setDamageCategory,
        payload: {
          category,
        },
      });
    },
    [dispatch]
  );

  return {
    ...context,
    setDamageCategory,
  };
}
