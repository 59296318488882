import { State } from "../types";

/**
 * Updates the selected damage category
 */
export default function setDamageCategory(state: State, payload): State {
  const { category } = payload;
  return {
    ...state,
    damageCategory: category,
  };
}
