import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import useBoxReturn from "boxReturn/useBoxReturn";

import {
  Hero,
  useBarcodeScanner,
  Card,
  CardContent,
  Tag,
  Typography,
  Button,
  CardHeader,
} from "@literati/dj-react";
import BoxBreadCrumb from "./BoxBreadCrumb";
import ToteBooksButton from "./ToteBooksButton";
import LoadingIndicator from "./LoadingIndicator";
import useHandleBookScan from "./useHandleBookScan";
import AddBookButton from "./AddBookButton";

const CATEGORY_PREFIX = "c-";
const SEVERITY_PREFIX = "s-";
const SEVERITY_REMOVE = "remove";

/**
 * A view for selecting the damages for a returned book.
 */
export default function BookView() {
  useHandleBookScan();
  useHandleDamageScans();

  return (
    <>
      <ViewHero />
      <div className="container">
        <div className="side-area">
          <Actions />
          <BookInfo />
          <BookList />
          <LoadingIndicator />
        </div>
        <div className="main-area">
          <SelectCategory />
          <SelectSeverity />
        </div>
      </div>
    </>
  );
}

function useCurrentBook() {
  const { currentReturnedBookId, returnedBooks } = useBoxReturn();
  const currenReturnedBook = returnedBooks.find(
    (rb) => rb.id === currentReturnedBookId
  );
  return currenReturnedBook;
}

function BookTitle() {
  const currenReturnedBook = useCurrentBook();
  const { book, barcode } = currenReturnedBook;
  return <>{book ? book.title : barcode}</>;
}

function ViewHero() {
  return (
    <Hero>
      <div className="hero-split">
        <div>
          <h1>
            <BoxBreadCrumb /> <BookTitle />
          </h1>
          <p>
            Scan damages to add them to this book. Scan another book to add it
            to the return. Scan the box to complete the return.
          </p>
        </div>
        <div className="hero-actions">
          <ToteBooksButton />
        </div>
      </div>
    </Hero>
  );
}

function useHandleDamageScans() {
  const { setDamageCategory, toggleDamage } = useBoxReturn();

  const validateScan = useCallback(
    (barcode: string) => {
      // check if it's a category
      if (barcode.startsWith(CATEGORY_PREFIX)) {
        const category = barcode.replace(CATEGORY_PREFIX, "");
        setDamageCategory(category);
        return;
      }
      // check if it's a severity
      if (barcode.startsWith(SEVERITY_PREFIX)) {
        let severity = barcode.replace(SEVERITY_PREFIX, "");
        if (severity === SEVERITY_REMOVE) severity = "";
        toggleDamage(severity);
      }
    },
    [toggleDamage, setDamageCategory]
  );

  useBarcodeScanner({
    types: ["UNKNOWN"],
    onScan: validateScan,
  });
}

function Actions() {
  return (
    <Card className="side-card">
      <AddBookButton />
    </Card>
  );
}

function BookInfo() {
  const { setDamageCategory, damageCategories, damageSeverities } =
    useBoxReturn();

  const currenReturnedBook = useCurrentBook();
  const { toteName, damages, barcode } = currenReturnedBook;

  const damageVariantMap = {
    minor: "info",
    moderate: "warning",
    severe: "danger",
  };

  const toteNameVariantMap = {
    trash: "danger",
    damaged: "warning",
    "non-stock-donation": "info",
  };

  return (
    <>
      <Card className="side-card">
        <CardContent>
          <h3>Barcode:</h3>
          <div>{barcode}</div>

          <h3>Tote Type:</h3>
          <Tag
            variant={toteNameVariantMap[toteName] || "success"}
            style={{ width: "100%" }}
          >
            <Typography size="sm" weight="bold">
              {toteName}
            </Typography>
          </Tag>

          <h3>Damages:</h3>

          {damages.length
            ? damages.map((damage) => (
                <Tag
                  className="pointer"
                  key={damage.category}
                  style={{ width: "100%", marginBottom: "5px" }}
                  onClick={() => setDamageCategory(damage.category)}
                  variant={damageVariantMap[damage.severity] || "default"}
                >
                  <Typography size="sm" weight="bold">
                    {damageCategories[damage.category]}
                  </Typography>

                  <Typography size="sm">
                    ({damageSeverities[damage.severity]})
                  </Typography>
                </Tag>
              ))
            : "None"}
        </CardContent>
      </Card>
    </>
  );
}

function BookList() {
  const { returnedBooks, selectReturnedBook } = useBoxReturn();
  return (
    <Card className="side-card">
      <CardHeader title="All Books" />
      <CardContent>
        <ol>
          {returnedBooks.map((rb) => (
            <li key={rb.id} onClick={() => selectReturnedBook(rb.id)}>
              <div>{rb.book ? rb.book.title : rb.barcode}</div>
            </li>
          ))}
        </ol>
      </CardContent>
    </Card>
  );
}

function SelectCategory() {
  const { damageCategories, damageCategory, setDamageCategory } =
    useBoxReturn();

  // if a damage category is already selected, render nothing
  if (damageCategory) return null;

  const damageCategoriesArray = Object.entries(damageCategories).map(
    ([value, display]) => [value, display]
  );

  // order alphabetically
  damageCategoriesArray.sort((a, b) =>
    a[1].toUpperCase().localeCompare(b[1].toUpperCase())
  );

  return (
    <>
      <h2>Select Damage</h2>
      <div className="damage-button-container">
        {damageCategoriesArray.map(([value, verbose]) => (
          <BarcodeButton
            key={value}
            prefix={CATEGORY_PREFIX}
            value={value}
            text={verbose}
            selectValue={setDamageCategory}
          />
        ))}
      </div>
    </>
  );
}

function SelectSeverity() {
  const { damageCategory, damageCategories, damageSeverities, toggleDamage } =
    useBoxReturn();

  const currenReturnedBook = useCurrentBook();

  const [notes, setNotes] = useState("");

  useEffect(() => {
    const { damages } = currenReturnedBook;
    const currentDamage = damages.find(
      (damage) => damage.category === damageCategory
    );
    setNotes(currentDamage?.notes || "");
  }, [setNotes, currenReturnedBook, damageCategory]);

  function handleNotesChange(event) {
    setNotes(event.target.value);
  }

  function select(severity: string) {
    if (severity === SEVERITY_REMOVE) severity = "";
    toggleDamage(severity, notes);
  }

  // only should severity options after a category is selected
  if (!damageCategory) return null;

  const needsNotes = damageCategory === "other";

  return (
    <>
      <h2>{damageCategories[damageCategory] || damageCategory}</h2>

      {needsNotes && (
        <>
          <p>Set Notes.</p>
          <textarea
            style={{ width: 300 }}
            value={notes}
            onChange={handleNotesChange}
            placeholder="Describe the damage"
          />
        </>
      )}

      <p>Scan or click to select the severity.</p>
      <div className="damage-button-container">
        {Object.entries(damageSeverities).map(([value, verbose]) => (
          <BarcodeButton
            key={value}
            prefix={SEVERITY_PREFIX}
            value={value}
            text={verbose}
            selectValue={select}
          />
        ))}
        <BarcodeButton
          prefix={SEVERITY_PREFIX}
          value={SEVERITY_REMOVE}
          text="None"
          selectValue={select}
        />
      </div>
    </>
  );
}

interface BarcodeButtonProps {
  prefix: string;
  value: string;
  text: string;
  selectValue(value: string): void;
}

function BarcodeButton(props: BarcodeButtonProps) {
  const { value, text, selectValue } = props;
  return (
    <Button
      size="lg"
      className="damage-button"
      onClick={() => selectValue(value)}
    >
      {text}
    </Button>
  );
}
