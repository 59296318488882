import * as React from "react";
import { useState } from "react";
import * as _ from "lodash";
import Select from "react-select";
import { gql, useLazyQuery } from "@apollo/client";

import Modal from "components/Modal";

interface Book {
  id: string;
  title: string;
  barcodes: string[];
  authorName: string;
  format: string;
}

interface Option {
  value: string;
  label: unknown;
}

interface Props {
  onBookSelect: (barcode: string) => void;
  onCancel: () => void;
}

/**
 * A modal that allows the user to search for a book
 * by title and select on of the options.
 */
export default function BookSearchModal(props: Props) {
  const { onCancel, onBookSelect } = props;

  const [options, setOptions] = useState<Option[]>([]);

  function onCompleted(data: { booksSearch: Book[] }) {
    const { booksSearch } = data;
    setOptions(
      _.map(booksSearch, (book) => ({
        value: book.barcodes[0],
        label: bookLabel(book),
      }))
    );
  }

  const [query, { loading }] = useLazyQuery(GQL, {
    onCompleted,
    fetchPolicy: "network-only",
  });
  const debouncedQuery = _.debounce(query, 100);
  function searchForTerm(term) {
    debouncedQuery({ variables: { term } });
  }

  function selectOption(option: Option) {
    onBookSelect(option.value);
  }

  return (
    <Modal onCancel={onCancel}>
      <h1>Add a Book by Title</h1>
      <Select
        autoFocus
        placeholder="Add a book..."
        value={null}
        isLoading={loading}
        onInputChange={searchForTerm}
        options={options}
        onChange={selectOption}
      />
    </Modal>
  );
}

function bookLabel(book: Book) {
  const { title, authorName, format } = book;
  return `${title}  - ${authorName} - ${format || "Unknown Format"}`;
}

const GQL = gql`
  query BooksSearch($term: String!) {
    booksSearch(term: $term) {
      id
      title
      authorName
      format
      barcodes
    }
  }
`;
