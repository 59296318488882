import { State } from "../types";

/**
 * Updates the stored values for the given returned book
 */
export default function updateReturnedBook(state: State, payload): State {
  const { returnedBook } = payload;
  const returnedBooks = [...state.returnedBooks];

  const idx = returnedBooks.findIndex(
    (existing) => existing.id === returnedBook.id
  );

  if (idx < 0) {
    // if it doesn't exist, add it to the end
    returnedBooks.push(returnedBook);
  } else {
    // if it does exist, replace the data
    returnedBooks[idx] = returnedBook;
  }

  const currentReturnedBookId = returnedBook.id;

  return {
    ...state,
    returnedBooks,
    currentReturnedBookId,
    damageCategory: null,
  };
}
