import * as React from "react";
import { useState } from "react";
import {
  Button,
  Hero,
  Typography,
  useBarcodeScanner,
  validateBarcode,
} from "@literati/dj-react";

import useStation from "hooks/useStation";
import "./station.css";

const BARCODE_TYPE = "STATION";

export default function RegisterStation() {
  return (
    <>
      <Hero>
        <h1>Return Station ID</h1>
        <p>Scan or type the Station ID for your current return station.</p>
        <CurrentValue />
      </Hero>

      <div className="content">
        <Form />
      </div>
    </>
  );
}

function CurrentValue() {
  const { stationId } = useStation();

  if (!stationId) return null;

  return (
    <div>
      Current:{" "}
      <Typography as="b" size="md" weight="heavy">
        {stationId}
      </Typography>
    </div>
  );
}

function Form() {
  const [localStationId, setLocalStationId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { setStationId } = useStation();

  useBarcodeScanner({
    types: [BARCODE_TYPE],
    onScan: (barcode) => setStationId(barcode),
  });

  function go() {
    const { type } = validateBarcode(localStationId);
    if (type !== BARCODE_TYPE) {
      setErrorMessage(`Invalid Station Id: ${localStationId}`);
      return;
    }
    setStationId(localStationId);
  }

  return (
    <div>
      <div className="form">
        <input
          type="text"
          onChange={(e) => setLocalStationId(e.target.value)}
        />
        <Button onClick={() => go()}>Assign Station</Button>
      </div>
      {errorMessage && (
        <Typography weight="bold">Error: {errorMessage}</Typography>
      )}
    </div>
  );
}
