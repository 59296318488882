import { useCallback } from "react";

import { ContextValue, ReducerType } from "../types";

/**
 * Adds a method for toggling signalling the tote view to be displayed or not
 */
export default function useShowTotes(context: ContextValue): ContextValue {
  const { dispatch } = context;

  context.setShowTotes = useCallback(
    (showTotes: boolean) => {
      dispatch({
        type: ReducerType.setShowTotes,
        payload: {
          showTotes,
        },
      });
    },
    [dispatch]
  );

  return context;
}
