import { State } from "../types";

/**
 * overrides showTotes
 */
export default function setShowTotes(state: State, payload): State {
  const { showTotes } = payload;
  return {
    ...state,
    showTotes,
  };
}
