import { ReducerType } from "../types";

import updateLoading from "./updateLoading";
import setBoxReturn from "./setBoxReturn";
import updateReturnedBook from "./updateReturnedBook";
import setDamageOptions from "./setDamageOptions";
import setErrors from "./setErrors";
import setCurrentBook from "./setCurrentBook";
import setNotes from "./setNotes";
import setDamageCategory from "./setDamageCategory";
import setShowTotes from "./setShowTotes";
import goBack from "./goBack";
import removeBook from "./removeBook";
import setIsEmpty from "./setIsEmpty";
import setIsUnsealed from "./setIsUnsealed";
import setIsReturnToSender from "./setIsReturnToSender";
import setCustomerRequestedCall from "./setCustomerRequestedCall";
import setCustomerRequestedCancel from "./setCustomerRequestedCancel";

const reducerMap = {
  [ReducerType.setCurrentBook]: setCurrentBook,
  [ReducerType.setErrors]: setErrors,
  [ReducerType.updateLoading]: updateLoading,
  [ReducerType.setBoxReturn]: setBoxReturn,
  [ReducerType.updateReturnedBook]: updateReturnedBook,
  [ReducerType.setDamageOptions]: setDamageOptions,
  [ReducerType.setNotes]: setNotes,
  [ReducerType.setDamageCategory]: setDamageCategory,
  [ReducerType.setShowTotes]: setShowTotes,
  [ReducerType.goBack]: goBack,
  [ReducerType.removeBook]: removeBook,
  [ReducerType.setIsEmpty]: setIsEmpty,
  [ReducerType.setIsUnsealed]: setIsUnsealed,
  [ReducerType.setIsReturnToSender]: setIsReturnToSender,
  [ReducerType.setCustomerRequestedCall]: setCustomerRequestedCall,
  [ReducerType.setCustomerRequestedCancel]: setCustomerRequestedCancel,
};

export default reducerMap;
