import * as React from "react";
import { createContext, useContext } from "react";

import useContextController from "global/hooks/useContextController";

import { ContextValue, State } from "./types";
import defaultState from "./constants";
import reducerFunctions from "./reducers";
import methodHooks from "./methods";

type ProviderProps = {
  children: React.ReactNode;
};

export function SearchProvider(props: ProviderProps) {
  const { children } = props;

  const initialState = defaultState();
  const value: ContextValue = useContextController<State, ContextValue>(
    initialState,
    reducerFunctions,
    methodHooks
  );
  return <Provider value={value}>{children}</Provider>;
}

/**
 * A convenience hook for accessing the context value
 */
export default function useSearch() {
  return useContext(ViewContext);
}

export const ViewContext = createContext<ContextValue>({} as ContextValue);

const { Provider } = ViewContext;
