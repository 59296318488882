import { State } from "../types";

interface Payload {
  currentReturnedBookId: string;
}
/**
 * overrides currentReturnedBookId
 */
export default function setCurrentBook(state: State, payload: Payload): State {
  const { currentReturnedBookId } = payload;
  return {
    ...state,
    currentReturnedBookId,
    damageCategory: null,
    showTotes: null,
  };
}
