import { State, SetHistoryPayload, BoxReturn } from "../types";

/**
 * overrides history
 */
export default function setHistory(
  state: State,
  payload: SetHistoryPayload
): State {
  const { history: historyData } = payload;

  const history: BoxReturn[] = historyData.map((boxReturn) => {
    return {
      ...boxReturn,
      intakeStartAt: new Date(boxReturn.intakeStartAt),
    };
  });

  return {
    ...state,
    history,
  };
}
