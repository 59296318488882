import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ReducerType, ContextValue } from "../types";

/**
 * Swaps out the toteId assigned to the toteName for the given stationId
 */
export default function useSwapTotes(context: ContextValue): ContextValue {
  const { stateRef, dispatch } = context;

  const onCompleted = useCallback(
    function onCompleted(data) {
      const { station } = data.intakeSwapTote;
      dispatch({
        type: ReducerType.updateTotes,
        payload: {
          totes: station.totes,
        },
      });
    },
    [dispatch]
  );

  const [mutate] = useMutation(GQL, { onCompleted });

  context.swapTote = useCallback(
    (toteName: string, toteId: string) => {
      const { stationId } = stateRef.current;

      const variables = {
        stationId,
        toteName,
        toteId,
      };
      return mutate({ variables });
    },
    [mutate, stateRef]
  );

  return context;
}

const GQL = gql`
  mutation IntakeSwapTote(
    $stationId: String!
    $toteName: String!
    $toteId: String!
  ) {
    intakeSwapTote(
      stationId: $stationId
      toteName: $toteName
      toteId: $toteId
    ) {
      success
      errorMessage
      errorData
      station {
        totes {
          id
          name
        }
      }
    }
  }
`;
