import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "global/constants";

import { ContextValue } from "../types";

/**
 * Redirects to the Register Station view if station ID is not assigned
 */
export default function useInitRedirect(context: ContextValue): ContextValue {
  const { stationId } = context;
  const navigate = useNavigate();

  useEffect(() => {
    if (stationId) return;
    navigate(URLS.INIT_STATION);
  }, [stationId, navigate]);

  return context;
}
