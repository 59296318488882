import * as React from "react";
import "./breadcrumb.css";
interface Props {
  text: string;
  onClick(): void;
}

export default function BreadCrumb(props: Props) {
  const { text, onClick } = props;

  return (
    <>
      <span className="breadcrumb" onClick={onClick}>
        {text}
      </span>{" "}
      &gt;
    </>
  );
}
