import { useCallback } from "react";
import { ContextValue } from "../types";

/**
 * Removes the toteId assigned to the given toteName
 */
export default function useRemoveTote(context: ContextValue): ContextValue {
  const { swapTote } = context;

  context.removeTote = useCallback(
    (toteName: string) => {
      swapTote(toteName, "");
    },
    [swapTote]
  );

  return context;
}
