import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "global/constants";
import { ContextValue } from "../types";

export default function useStartReturn(context: ContextValue): ContextValue {
  const navigate = useNavigate();

  const startReturn = useCallback(
    function startReturn(boxShipmentId: number) {
      navigate(`${URLS.BOX}/${boxShipmentId}`);
    },
    [navigate]
  );

  return {
    ...context,
    startReturn,
  };
}
