import { useCallback } from "react";

import { ContextValue, ReducerType } from "../types";

/**
 * This context hook add methods for describing box state
 */
export default function useIntakeOptions(context: ContextValue): ContextValue {
  const { dispatch } = context;

  context.setIsEmpty = useCallback(
    function setIsEmpty(isEmpty: boolean) {
      dispatch({
        type: ReducerType.setIsEmpty,
        payload: {
          isEmpty,
        },
      });
    },
    [dispatch]
  );

  context.setIsUnsealed = useCallback(
    function setIsUnsealed(isUnsealed: boolean) {
      dispatch({
        type: ReducerType.setIsUnsealed,
        payload: {
          isUnsealed,
        },
      });
    },
    [dispatch]
  );

  context.setIsReturnToSender = useCallback(
    function setIsReturnToSender(isReturnToSender: boolean) {
      dispatch({
        type: ReducerType.setIsReturnToSender,
        payload: {
          isReturnToSender,
        },
      });
    },
    [dispatch]
  );

  context.setCustomerRequestedCall = useCallback(
    function setCustomerRequestedCall(customerRequestedCall: boolean) {
      dispatch({
        type: ReducerType.setCustomerRequestedCall,
        payload: {
          customerRequestedCall,
        },
      });
    },
    [dispatch]
  );
  context.setCustomerRequestedCancel = useCallback(
    function setCustomerRequestedCancel(customerRequestedCancel: boolean) {
      dispatch({
        type: ReducerType.setCustomerRequestedCancel,
        payload: {
          customerRequestedCancel,
        },
      });
    },
    [dispatch]
  );

  return context;
}
