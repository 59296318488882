import { State } from "../types";

export default function setDamageOptions(state: State, payload): State {
  const { intakeDamageCategories, intakeDamageSeverities } = payload;

  const damageCategories = {};
  intakeDamageCategories.forEach((choice) => {
    damageCategories[choice.value] = choice.verbose;
  });

  const damageSeverities = {};
  intakeDamageSeverities.forEach((choice) => {
    damageSeverities[choice.value] = choice.verbose;
  });

  return {
    ...state,
    damageCategories,
    damageSeverities,
  };
}
