import { State } from "../types";

/**
 * Brings the user back the box detail page
 */
export default function goBack(state: State, payload): State {
  return {
    ...state,
    showTotes: false,
    currentReturnedBookId: null,
    damageCategory: null,
  };
}
