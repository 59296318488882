import { useCallback, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import useTrackLoading from "../helpers/useTrackLoading";

export default function useGetDamageOptions(
  context: ContextValue
): ContextValue {
  const { dispatch, onError } = context;

  const onCompleted = useCallback(
    function onCompleted(data) {
      const { intakeDamageCategories, intakeDamageSeverities } = data;
      dispatch({
        type: ReducerType.setDamageOptions,
        payload: {
          intakeDamageCategories,
          intakeDamageSeverities,
        },
      });
    },
    [dispatch]
  );

  const [getDamageOptions, { loading }] = useLazyQuery(GQL, {
    onCompleted,
    onError,
  });

  useTrackLoading(loading, dispatch);

  // get the damage options on first load
  useEffect(() => {
    getDamageOptions();
  }, [getDamageOptions]);

  return {
    ...context,
    getDamageOptions,
  };
}

const GQL = gql`
  query IntakeDamageOptions {
    intakeDamageCategories {
      value
      verbose
    }
    intakeDamageSeverities {
      value
      verbose
    }
  }
`;
