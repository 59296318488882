import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import useTrackLoading from "../helpers/useTrackLoading";
import { BOX_RETURN_BASE } from "../helpers/fragments";

export default function useReverseBoxReturn(
  context: ContextValue
): ContextValue {
  const { stateRef, dispatch, checkForErrors, onError } = context;

  const onCompleted = useCallback(
    function onCompleted({ intakeReverseBoxReturn: res }) {
      if (checkForErrors(res)) return;
      const { boxReturn } = res;
      dispatch({
        type: ReducerType.setBoxReturn,
        payload: {
          boxReturn,
        },
      });
    },
    [checkForErrors, dispatch]
  );

  const [mutate, { loading }] = useMutation(GQL, { onCompleted, onError });

  useTrackLoading(loading, dispatch);

  context.reverseBoxReturn = useCallback(
    function reverseBoxReturn() {
      const { boxShipmentId, stationId } = stateRef.current;
      const variables = {
        boxShipmentId,
        stationId,
      };
      return mutate({ variables });
    },
    [mutate, stateRef]
  );

  return context;
}

const GQL = gql`
  mutation IntakeReverseBoxReturn($boxShipmentId: ID!, $stationId: String!) {
    intakeReverseBoxReturn(
      boxShipmentId: $boxShipmentId
      stationId: $stationId
    ) {
      success
      errorMessage
      errorData
      boxReturn {
        ...BoxReturnBase
      }
    }
  }
  ${BOX_RETURN_BASE}
`;
