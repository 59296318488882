import { ContextValue } from "../types";
import useBarcodeSearch from "./useBarcodeSearch";
import useFetchHistory from "./useFetchHistory";

import useSetErrors from "./useSetErrors";
import useStartReturn from "./useStartReturn";

const methodHooks: { (context: ContextValue): ContextValue }[] = [
  useSetErrors,
  useStartReturn,
  useBarcodeSearch,
  useFetchHistory,
];

export default methodHooks;
