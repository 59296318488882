import * as React from "react";

import { Button, useBarcodeScanner } from "@literati/dj-react";

import useBoxReturn from "boxReturn/useBoxReturn";

/**
 * A button and barcode listener for showing the tote view
 */
export default function ToteBooksButton() {
  const { setShowTotes } = useBoxReturn();

  useBarcodeScanner({
    types: ["PACKAGE"],
    onScan: (barcode) => setShowTotes(true),
  });

  return <Button onClick={() => setShowTotes(true)}>Tote Books</Button>;
}
