import { ContextValue } from "../types";
import useGetTotes from "./useGetTotes";
import useInitRedirect from "./useInitRedirect";
import useRemoveTote from "./useRemoveTote";
import useSetStationId from "./useSetStationId";
import useSwapTotes from "./useSwapTote";
import useGetTotesEffect from "./useGetTotesEffect";
import useSetToteModal from "./useSetToteModal";

const hooks: { (context: ContextValue): ContextValue }[] = [
  useGetTotes,
  useSwapTotes,
  useRemoveTote,
  useSetStationId,
  useInitRedirect,
  useGetTotesEffect,
  useSetToteModal,
];

export default hooks;
