import { State } from "../types";

interface Payload {
  isUnsealed: boolean;
}

export default function setIsUnsealed(state: State, payload: Payload): State {
  const { isUnsealed } = payload;

  return {
    ...state,
    isUnsealed,
  };
}
