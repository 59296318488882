import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import useTrackLoading from "../helpers/useTrackLoading";

/**
 * Adds a method for removing a returned book
 */
export default function useRemoveReturnedBook(
  context: ContextValue
): ContextValue {
  const { dispatch, checkForErrors, onError } = context;

  const [mutate, { loading }] = useMutation(GQL, { onError });

  useTrackLoading(loading, dispatch);

  const removeReturnedBook = useCallback(
    (returnedBookId: string) => {
      const variables = {
        returnedBookId,
      };
      return mutate({ variables }).then(({ data }) => {
        const res = data.intakeRemoveReturnedBook;
        if (checkForErrors(res)) return;
        dispatch({
          type: ReducerType.removeBook,
          payload: {
            returnedBookId,
          },
        });
      });
    },
    [mutate, dispatch, checkForErrors]
  );

  return {
    ...context,
    removeReturnedBook,
  };
}

const GQL = gql`
  mutation IntakeRemoveReturnedBook($returnedBookId: ID!) {
    intakeRemoveReturnedBook(returnedBookId: $returnedBookId) {
      success
      errorMessage
      errorData
    }
  }
`;
