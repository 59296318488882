import { State } from "../types";

/**
 * Update the value of showToteSwapModal
 */
export default function setToteModal(state: State, payload): State {
  const { toteName } = payload;
  return {
    ...state,
    activeToteName: toteName,
  };
}
