import * as React from "react";

import { ViewProvider } from "./useBoxReturn";
import View from "./boxReturnView";

export default function WrappedView() {
  return (
    <ViewProvider>
      <View />
    </ViewProvider>
  );
}
