import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { URLS } from "global/constants";
import { ContextValue, ReducerType } from "../types";
import { STORAGE_KEY } from "../constants";

/**
 * Saves the stationId to the context and saves it to local storage
 */
export default function useSetStationId(context: ContextValue): ContextValue {
  const { dispatch } = context;
  const navigate = useNavigate();

  context.setStationId = useCallback(
    (stationId: string) => {
      localStorage.setItem(STORAGE_KEY.STATION_ID, stationId);
      dispatch({
        type: ReducerType.updateStationId,
        payload: { stationId },
      });
      navigate(URLS.SEARCH);
    },
    [dispatch, navigate]
  );

  return context;
}
