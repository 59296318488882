import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { URLS } from "global/constants";

import { ContextValue } from "../types";

export default function useCloseView(context: ContextValue): ContextValue {
  // eslint-disable-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  const closeView = useCallback(() => {
    navigate(URLS.SEARCH);
  }, [navigate]);

  return {
    ...context,
    closeView,
  };
}
