import { useCallback, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import useTrackLoading from "../helpers/useTrackLoading";
import { BOX_RETURN_BASE } from "../helpers/fragments";

/**
 * This context hook creates a BoxReturn object on the backend
 * for the given box shipment id, on mount.
 */
export default function useStartBoxReturn(context: ContextValue): ContextValue {
  const { state, stateRef, dispatch, checkForErrors, onError } = context;

  const onCompleted = useCallback(
    function onCompleted({ intakeStartBoxReturn: res }) {
      if (checkForErrors(res)) return;
      const { boxReturn } = res;
      dispatch({
        type: ReducerType.setBoxReturn,
        payload: {
          boxReturn,
        },
      });
    },
    [checkForErrors, dispatch]
  );

  const [mutate, { loading }] = useMutation(GQL, { onCompleted, onError });

  useTrackLoading(loading, dispatch);

  const startBoxReturn = useCallback(() => {
    const { boxShipmentId, stationId } = stateRef.current;
    const variables = {
      boxShipmentId,
      stationId,
    };
    return mutate({ variables });
  }, [mutate, stateRef]);

  // Load box once station is set
  const { stationId, boxShipmentId } = state;
  useEffect(() => {
    if (!stationId) return;
    startBoxReturn();
  }, [stationId, boxShipmentId, startBoxReturn]);

  return {
    ...context,
  };
}

const GQL = gql`
  mutation IntakeStartBoxReturn($boxShipmentId: ID!, $stationId: String!) {
    intakeStartBoxReturn(boxShipmentId: $boxShipmentId, stationId: $stationId) {
      success
      errorMessage
      errorData
      boxReturn {
        ...BoxReturnBase
      }
    }
  }
  ${BOX_RETURN_BASE}
`;
