import * as React from "react";

import useBoxReturn from "../useBoxReturn";
import Modal from "components/Modal";

import BoxView from "./BoxView";
import BookView from "./BookView";
import ToteView from "./ToteView";
import ReverseReturnBanner from "./ReverseReturnBanner";
import "./boxReturn.css";

/**
 * The main component for controlling the current view
 * of the box intake process.
 */
export default function View() {
  return (
    <>
      <ReverseReturnBanner />
      <SubView />
      <Errors />
    </>
  );
}

/**
 * Controllers which sub view should be rendered based on the context state
 */
function SubView() {
  const { currentReturnedBookId, showTotes } = useBoxReturn();
  if (showTotes) return <ToteView />;
  if (currentReturnedBookId) return <BookView />;
  return <BoxView />;
}

/**
 * A modal that displays any time an error is received by the backend
 */
function Errors() {
  const { errors, setErrors } = useBoxReturn();

  if (!errors.length) return null;

  return (
    <Modal onCancel={() => setErrors([])}>
      <h1 className="error">Error</h1>
      {errors.map((error) => (
        <p key={error} className="error">
          {error}
        </p>
      ))}
    </Modal>
  );
}
