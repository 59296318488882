import * as React from "react";
import { useState, useCallback } from "react";

import { useBarcodeScanner, validateBarcode, Button } from "@literati/dj-react";

import useStation from "hooks/useStation";
import Modal from "components/Modal";

const TOTE_NAME_BARCODE_PREFIX = "tt-";

/**
 * This component sets up a listener  TOTE barcode scans
 * then shows a modal for swapping the Tote assigned to the ActiveToteName.
 *
 * The modal can also be triggered by passing a tote name to useStation.setToteModal
 */
export default function SwapToteModalListener() {
  const { activeToteName } = useStation();

  // do not have Listen and SwapModal active at the same time as both listen for tote scans.
  if (activeToteName) return <SwapModal />;
  return <Listener />;
}

/**
 * This component listens for a tote name barcode to be scanned.
 *
 * The idea is that a user will scan the slot where a tote lives
 * to open a modal to swap the tote.
 */
function Listener() {
  const { setToteModal } = useStation();

  const validateScan = useCallback(
    (barcode: string) => {
      // check if the scan is a tote name barcode
      if (!barcode.startsWith(TOTE_NAME_BARCODE_PREFIX)) return;
      const toteName = barcode.replace(TOTE_NAME_BARCODE_PREFIX, "");
      setToteModal(toteName);
    },
    [setToteModal]
  );

  useBarcodeScanner({
    types: ["UNKNOWN"],
    onScan: validateScan,
  });

  return null;
}

/**
 * A modal used to assigning a new toteId to the toteName for the current returns station.
 */
function SwapModal() {
  const { activeToteName, setToteModal, swapTote } = useStation();

  const [toteId, setToteId] = useState("");
  const [error, setError] = useState("");

  useBarcodeScanner({
    types: ["TOTE"],
    onScan: (barcode) => swapTote(activeToteName, barcode),
  });

  const submit = useCallback(() => {
    const { type } = validateBarcode(toteId.toLocaleUpperCase());
    if (type !== "TOTE") {
      console.error("invalid type", type);
      setError("Invalid Tote Id");
      return;
    }
    swapTote(activeToteName, toteId);
  }, [activeToteName, toteId, swapTote]);

  return (
    <Modal onCancel={() => setToteModal("")}>
      <h1>SWAP TOTE for {activeToteName}</h1>
      <p>
        Scan or type the Tote ID that should be used for tote type{" "}
        <b>{activeToteName}</b>.
      </p>
      <div className="input-with-button-container">
        <input type="text" onChange={(e) => setToteId(e.target.value)} />
        <Button onClick={() => submit()}>Save</Button>
      </div>
      {error && <div className="error">Error: {error}</div>}
    </Modal>
  );
}
