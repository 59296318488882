import { useEffect } from "react";
import { ReducerType } from "../types";

export default function useTrackLoading(isLoading, dispatch) {
  useEffect(() => {
    if (!isLoading) return;
    dispatch({
      type: ReducerType.updateLoading,
      payload: {
        loading: true,
      },
    });
    // call loading:false on clean up
    // this will be called when isLoading is changed to false
    // but also on unmount of useTrackLoading
    return () =>
      dispatch({
        type: ReducerType.updateLoading,
        payload: {
          loading: false,
        },
      });
  }, [isLoading, dispatch]);
}
