import * as React from "react";
import { createContext, useContext } from "react";

import useContextController from "global/hooks/useContextController";

import { State, ContextValue } from "./types";
import defaultState from "./constants";
import reducerMap from "./reducers";
import hooks from "./hooks";

type Props = {
  children: React.ReactNode;
};

export function StationProvider(props: Props) {
  const { children } = props;

  const initialState = defaultState();

  const value = useContextController<State, ContextValue>(
    initialState,
    reducerMap,
    hooks
  );

  return <Provider value={value}>{children}</Provider>;
}

export const Context = createContext<ContextValue>({} as ContextValue);

const { Provider } = Context;

export default function useStation() {
  return useContext(Context);
}
