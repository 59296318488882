import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import { RETURNED_BOOK_BASE } from "../helpers/fragments";
import useTrackLoading from "../helpers/useTrackLoading";

export default function useToggleDamage(context: ContextValue): ContextValue {
  const { stateRef, dispatch, checkForErrors, onError } = context;

  const onCompleted = useCallback(
    function onCompleted({ intakeToggleReturnedDamage: res }) {
      if (checkForErrors(res)) return;
      const { returnedBook } = res;
      dispatch({
        type: ReducerType.updateReturnedBook,
        payload: {
          returnedBook,
        },
      });
    },
    [checkForErrors, dispatch]
  );

  const [mutate, { loading }] = useMutation(GQL, { onCompleted, onError });

  useTrackLoading(loading, dispatch);

  const toggleDamage = useCallback(
    (severity: string, notes = "") => {
      const { currentReturnedBookId, damageCategory } = stateRef.current;
      const variables = {
        returnedBookId: currentReturnedBookId,
        category: damageCategory,
        severity,
        notes,
      };
      return mutate({ variables });
    },
    [mutate, stateRef]
  );

  return {
    ...context,
    toggleDamage,
  };
}

const GQL = gql`
  mutation IntakeToggleReturnedDamage(
    $returnedBookId: ID!
    $category: String!
    $severity: String!
    $notes: String
  ) {
    intakeToggleReturnedDamage(
      returnedBookId: $returnedBookId
      category: $category
      severity: $severity
      notes: $notes
    ) {
      success
      errorMessage
      errorData
      returnedBook {
        ...ReturnedBookBase
      }
    }
  }
  ${RETURNED_BOOK_BASE}
`;
