import { State } from "./types";

export default function defaultState(overrides: object = {}): State {
  return {
    stationId: localStorage.getItem(STORAGE_KEY.STATION_ID),
    totes: null,
    ...overrides,
  };
}

export const STORAGE_KEY = {
  STATION_ID: "station-id",
};
