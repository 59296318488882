import { State } from "../types";

/**
 * Convert a list of totes into an object to easily
 * map tote_name to tote_id
 */
export default function updateTotes(state: State, payload): State {
  const { totes: totesList } = payload;

  const totes = {};
  totesList.forEach((tote) => {
    totes[tote.name] = tote.id;
  });

  return {
    ...state,
    totes,
    activeToteName: null,
  };
}
