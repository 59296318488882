import { State, RemoveBookPayload } from "../types";

/**
 * Removes the matching returned book object
 */
export default function removeBook(
  state: State,
  payload: RemoveBookPayload
): State {
  const { returnedBookId } = payload;

  // if the box doesn't have intake notes use the state value
  const returnedBooks = state.returnedBooks.filter(
    (rb) => rb.id !== returnedBookId
  );

  return {
    ...state,
    returnedBooks,
    currentReturnedBookId: null,
  };
}
