import { ReducerType } from "../types";

import updateTotes from "./updateTotes";
import updateStationId from "./updateStationId";
import setToteModal from "./setToteModal";

const reducerMap = {
  [ReducerType.updateStationId]: updateStationId,
  [ReducerType.updateTotes]: updateTotes,
  [ReducerType.setToteModal]: setToteModal,
};

export default reducerMap;
