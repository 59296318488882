import * as React from "react";
import { useState } from "react";
import { Button } from "@literati/dj-react";

import BookSearchModal from "components/BookSearchModal/BookSearchModal";
import useBoxReturn from "boxReturn/useBoxReturn";

export default function AddBookButton() {
  const { createReturnedBook } = useBoxReturn();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  function createReturn(barcode: string) {
    createReturnedBook(barcode);
    setModalIsOpen(false);
  }

  return (
    <div>
      <Button
        className="button-xs"
        size="sm"
        onClick={() => setModalIsOpen(true)}
      >
        + Book By Title
      </Button>
      {modalIsOpen && (
        <BookSearchModal
          onBookSelect={createReturn}
          onCancel={() => setModalIsOpen(false)}
        />
      )}
    </div>
  );
}
