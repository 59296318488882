import * as React from "react";

import { SearchProvider } from "./useSearch";
import View from "./SearchView";

export default function SearchView() {
  return (
    <SearchProvider>
      <View />
    </SearchProvider>
  );
}
