import * as React from "react";

import useStation from "hooks/useStation";
import useBoxReturn from "boxReturn/useBoxReturn";
import { ReturnedBook as ReturnedBookType } from "boxReturn/useBoxReturn/types";

import { Button, Hero, useBarcodeScanner } from "@literati/dj-react";

import useHandleBookScan from "./useHandleBookScan";
import BoxBreadCrumb from "./BoxBreadCrumb";

/**
 * A view showing the returned books organized by the tote
 * they should be placed in.
 */
export default function ToteView() {
  useHandleBookScan();

  return (
    <>
      <ViewHero />
      <div className="container">
        <div className="main-area">
          <Totes />
        </div>
      </div>
    </>
  );
}

function ViewHero() {
  return (
    <Hero>
      <div className="hero-split">
        <div>
          <h1>
            <BoxBreadCrumb /> Tote Books
          </h1>
          <p>
            Place all the books in the correct totes. Scan or click on a book to
            adjust damages. Scan or click a Tote Type to assign new Tote ID.
          </p>
        </div>
        <div>
          <CloseBoxButton />
        </div>
      </div>
    </Hero>
  );
}

/**
 * A button for completing the return of a box.
 * A scan of any box will also trigger the return as complete.
 */
function CloseBoxButton() {
  const { closeBoxReturn } = useBoxReturn();

  useBarcodeScanner({
    types: ["PACKAGE"],
    onScan: (barcode) => closeBoxReturn(),
  });

  return (
    <div>
      <Button onClick={() => closeBoxReturn()}>Complete Return</Button>
    </div>
  );
}

/**
 * Organizes the returned book by the tote they should be placed in
 */
function Totes() {
  const { returnedBooks } = useBoxReturn();

  // group returned books by tote name
  const totes: { [toteName: string]: ReturnedBookType[] } = {};
  returnedBooks.forEach((rb) => {
    const toteBooks = totes[rb.toteName] || [];
    toteBooks.push(rb);
    totes[rb.toteName] = toteBooks;
  });

  return (
    <>
      {Object.entries(totes).map(([toteName, returnedBooks]) => (
        <ToteBooks
          key={toteName}
          toteName={toteName}
          returnedBooks={returnedBooks}
        />
      ))}
    </>
  );
}

interface ToteBookProps {
  toteName: string;
  returnedBooks: ReturnedBookType[];
}
/**
 * A single tote and the books assigned to it.
 */
function ToteBooks(props: ToteBookProps) {
  const { toteName, returnedBooks } = props;

  return (
    <div>
      <ToteHeader toteName={toteName} />
      <table style={{ tableLayout: "fixed" }}>
        {returnedBooks.map((rb) => (
          <ReturnedBook key={rb.id} returnedBook={rb} />
        ))}
      </table>
    </div>
  );
}

interface ToteHeaderProps {
  toteName: string;
}
/**
 * Displays the info for a tote as well as actions for triggering a tote swap
 */
function ToteHeader(props: ToteHeaderProps) {
  const { toteName } = props;
  const { totes, setToteModal } = useStation();
  const toteId = totes[toteName];

  const toteNameDisplay = toteName.toUpperCase();

  // only books going into the restock flow need tote ids assigned
  const needsToteId = toteName.includes("restock");

  if (!needsToteId) return <h2>{toteNameDisplay}</h2>;

  return (
    <>
      <h2 className={!toteId && "error"} onClick={() => setToteModal(toteName)}>
        {toteNameDisplay}: <small>{toteId || "Set Tote"}</small>
      </h2>
    </>
  );
}

interface ReturnedBookProps {
  returnedBook: ReturnedBookType;
}

function ReturnedBook(props: ReturnedBookProps) {
  const { returnedBook } = props;
  const { book, damages } = returnedBook;
  const { selectReturnedBook, damageCategories, damageSeverities } =
    useBoxReturn();

  return (
    <tr onClick={() => selectReturnedBook(returnedBook.id)}>
      <td>{book ? book.title : "Unknown"}</td>
      <td>
        {damages.length
          ? damages.map((damage) => (
              <div key={damage.category}>
                {damageCategories[damage.category]} (
                {damageSeverities[damage.severity]})
              </div>
            ))
          : "No Damages"}
      </td>
    </tr>
  );
}
