import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import { RETURNED_BOOK_BASE } from "../helpers/fragments";
import useTrackLoading from "../helpers/useTrackLoading";

export default function useCreateReturnedBook(context: ContextValue) {
  const { stateRef, dispatch, checkForErrors, onError } = context;

  const onCompleted = useCallback(
    function onCompleted({ intakeCreateReturnedBook: res }) {
      if (checkForErrors(res)) return;
      const { returnedBook } = res;
      dispatch({
        type: ReducerType.updateReturnedBook,
        payload: {
          returnedBook,
        },
      });
    },
    [checkForErrors, dispatch]
  );

  const [mutate, { loading }] = useMutation(GQL, { onCompleted, onError });

  useTrackLoading(loading, dispatch);

  const createReturnedBook = useCallback(
    (barcode: string) => {
      const { boxReturn } = stateRef.current;

      const variables = {
        boxReturnId: boxReturn.boxShipmentId,
        barcode,
      };
      return mutate({ variables });
    },
    [mutate, stateRef]
  );

  return {
    ...context,
    createReturnedBook,
  };
}

const GQL = gql`
  mutation IntakeCreateReturnedBook($boxReturnId: ID!, $barcode: String!) {
    intakeCreateReturnedBook(boxReturnId: $boxReturnId, barcode: $barcode) {
      success
      errorMessage
      errorData
      returnedBook {
        ...ReturnedBookBase
      }
    }
  }
  ${RETURNED_BOOK_BASE}
`;
