import * as React from "react";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import useStation from "hooks/useStation";
import useContextController from "global/hooks/useContextController";

import { State, ContextValue } from "./types";
import defaultState from "./constants";
import reducerMap from "./reducers";
import hooks from "./hooks";

type ViewProps = {
  children: React.ReactNode;
};

export function ViewProvider(props: ViewProps) {
  const { children } = props;

  const { boxShipmentId } = useParams();
  const { stationId } = useStation();

  const initialState = defaultState({
    boxShipmentId,
    stationId,
  });

  const value = useContextController<State, ContextValue>(
    initialState,
    reducerMap,
    hooks
  );

  return <Provider value={value}>{children}</Provider>;
}

const ViewContext = createContext<ContextValue>({} as ContextValue);

const { Provider } = ViewContext;

export default function useViewContext() {
  return useContext(ViewContext);
}
