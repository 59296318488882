import { useCallback } from "react";

import { ContextValue, ReducerType } from "../types";
/**
 * This context hook add a method for updating the value
 * of the intake notes
 */
export default function useSetNotes(context: ContextValue): ContextValue {
  const { dispatch } = context;

  context.setNotes = useCallback(
    function setNotes(intakeNotes: string) {
      dispatch({
        type: ReducerType.setNotes,
        payload: {
          intakeNotes,
        },
      });
    },
    [dispatch]
  );

  return context;
}
