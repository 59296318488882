import { useCallback } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import { ContextValue } from "../types";

export default function useBarcodeSearch(context: ContextValue) {
  const { checkForErrors, startReturn, onError } = context;

  const onCompleted = useCallback(
    function onCompleted({ intakeSearch: res }) {
      if (checkForErrors(res)) return;
      const { boxShipmentId } = res;
      startReturn(boxShipmentId);
    },
    [checkForErrors]
  );

  const [query] = useLazyQuery(GQL, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });

  const barcodeSearch = useCallback(
    function barcodeSearch(barcode: string) {
      const variables = {
        barcode,
      };
      return query({ variables });
    },
    [query]
  );

  return {
    ...context,
    barcodeSearch,
  };
}

const GQL = gql`
  query IntakeSearch($barcode: String!) {
    intakeSearch(barcode: $barcode) {
      success
      errorMessage
      boxShipmentId
    }
  }
`;
