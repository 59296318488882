import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue } from "../types";
import useTrackLoading from "../helpers/useTrackLoading";
import { useRefFromState } from "global/hooks/state";

/**
 * This context hook add a method for closing the intake step for the current box,
 * then redirect to the search page.
 */
export default function useCloseBoxReturn(context: ContextValue): ContextValue {
  const { stateRef, dispatch, checkForErrors, onError, closeView } = context;

  const onCompleted = useCallback(
    function onCompleted({ intakeCloseBoxReturn: res }) {
      if (checkForErrors(res)) return;
      closeView();
    },
    [checkForErrors, closeView]
  );

  const [mutate, { loading }] = useMutation(GQL, { onCompleted, onError });
  const loadingRef = useRefFromState(loading);

  useTrackLoading(loading, dispatch);

  const closeBoxReturn = useCallback(() => {
    // prevent double calling this endpoint
    if (loadingRef.current) return;
    const {
      boxReturn,
      stationId,
      intakeNotes,
      isEmpty,
      isUnsealed,
      isReturnToSender,
      customerRequestedCall,
      customerRequestedCancel,
    } = stateRef.current;

    const variables = {
      boxReturnId: boxReturn.boxShipmentId,
      stationId,
      intakeNotes,
      isEmpty,
      isUnsealed,
      isReturnToSender,
      customerRequestedCall,
      customerRequestedCancel,
    };

    return mutate({ variables });
  }, [mutate, stateRef, loadingRef]);

  return {
    ...context,
    closeBoxReturn,
  };
}

const GQL = gql`
  mutation IntakeCloseBoxReturn(
    $boxReturnId: ID!
    $stationId: String!
    $intakeNotes: String
    $isReturnToSender: Boolean
    $isEmpty: Boolean
    $isUnsealed: Boolean
    $customerRequestedCall: Boolean
    $customerRequestedCancel: Boolean
  ) {
    intakeCloseBoxReturn(
      boxReturnId: $boxReturnId
      stationId: $stationId
      intakeNotes: $intakeNotes
      isReturnToSender: $isReturnToSender
      isEmpty: $isEmpty
      isUnsealed: $isUnsealed
      customerRequestedCall: $customerRequestedCall
      customerRequestedCancel: $customerRequestedCancel
    ) {
      success
      errorMessage
      errorData
    }
  }
`;
