import * as React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import {
  KeyboardBarcodeScannerProvider,
  UserProvider,
} from "@literati/dj-react";
import { ApolloProvider } from "@apollo/client";
import "@literati/dj-css";

import apollo from "global/apollo";
import { URLS } from "global/constants";
import SearchView from "./search";
import BoxReturnView from "./boxReturn";
import RegisterStationView from "./RegisterStation";
import Nav from "components/Nav";
import { StationProvider } from "./hooks/useStation";

import SwapToteModal from "SwapToteModal";

export default function App() {
  return (
    <AppProviders>
      <SwapToteModal />
      <Nav />
      <AppRoutes />
    </AppProviders>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path={URLS.INIT_STATION} element={<RegisterStationView />} />
      <Route path={URLS.SEARCH} element={<SearchView />} />
      <Route path={`${URLS.BOX}/:boxShipmentId`} element={<BoxReturnView />} />
    </Routes>
  );
}

type AppProvidersProps = {
  children: React.ReactNode;
};

function AppProviders(props: AppProvidersProps) {
  return (
    <ApolloProvider client={apollo}>
      <BrowserRouter>
        <KeyboardBarcodeScannerProvider>
          <UserProvider
            csrfToken="csrftoken-kids"
            authBaseUrl={process.env.REACT_APP_AUTH_BASE_URL}
            // TODO: set autoLogout to true once we have a bugfix
            autoLogout={false}
          >
            <StationProvider>{props.children}</StationProvider>
          </UserProvider>
        </KeyboardBarcodeScannerProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}
