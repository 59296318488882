import { State } from "./types";

export default function defaultState(overrides: object = {}): State {
  const { boxShipmentId = null } = overrides as State;
  const intakeNotes =
    localStorage.getItem(STORAGE_KEY.BOX_NOTES(boxShipmentId)) || "";

  return {
    boxShipmentId: null,
    errors: [],
    stationId: "unknown",
    isLoading: false,
    boxReturn: null,
    returnedBooks: [],
    intakeNotes,
    damageCategories: {},
    damageSeverities: {},
    loadingCount: 0,
    showTotes: false,
    isEmpty: false,
    isUnsealed: false,
    isReturnToSender: false,
    customerRequestedCall: false,
    customerRequestedCancel: false,
    ...overrides,
  };
}

export const STORAGE_KEY = {
  BOX_NOTES: (boxId) => `box/${boxId}/notes`,
};
