interface Totes {
  [value: string]: string;
}

export interface State {
  stationId?: string;
  totes?: Totes;
  activeToteName?: string;
}

export interface Methods {
  swapTote(toteName: string, toteId: string): Promise<object>;
  getTotes(): Promise<object>;
  removeTote(toteName: string): void;
  setStationId(id: string): void;
  setToteModal(toteName: string): void;
}

export enum ReducerType {
  updateTotes,
  updateStationId,
  setToteModal,
}

export interface StateRef {
  current: State;
}
export interface ReducerAction {
  type: ReducerType;
  payload?: object;
}

export type Dispatch = (a: ReducerAction) => void;

export type ContextValue = State &
  Methods & {
    state: State;
    stateRef: StateRef;
    dispatch: Dispatch;
    methods: Methods;
  };
