import { State } from "../types";

/**
 * Keeps track of how many things are loading
 * and sets the boolean isLoading if something is still loading
 */
export default function updateLoading(state: State, payload): State {
  const { loading } = payload;
  let { loadingCount } = state;

  if (loading) {
    loadingCount += 1;
  } else {
    loadingCount -= 1;
  }
  // do no go below 0
  if (loadingCount < 0) loadingCount = 0;

  const isLoading = loadingCount > 0;

  return {
    ...state,
    loadingCount,
    isLoading,
  };
}
