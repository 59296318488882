import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue } from "../types";
import useTrackLoading from "../helpers/useTrackLoading";

/**
 * This context hook cancels a Box Return
 */
export default function useCancelBoxReturn(
  context: ContextValue
): ContextValue {
  const { state, stateRef, dispatch, checkForErrors, onError, closeView } =
    context;

  const onCompleted = useCallback(
    function onCompleted({ intakeCancelBoxReturn: res }) {
      if (checkForErrors(res)) return;
      closeView();
    },
    [checkForErrors, closeView]
  );

  const [mutate, { loading }] = useMutation(GQL, { onCompleted, onError });

  useTrackLoading(loading, dispatch);

  const cancelBoxReturn = useCallback(() => {
    const { boxShipmentId, stationId } = stateRef.current;
    const variables = {
      boxShipmentId,
      stationId,
    };
    return mutate({ variables });
  }, [mutate, stateRef]);

  return {
    ...context,
    cancelBoxReturn,
  };
}

const GQL = gql`
  mutation IntakeCancelBoxReturn($boxShipmentId: ID!, $stationId: String!) {
    intakeCancelBoxReturn(
      boxShipmentId: $boxShipmentId
      stationId: $stationId
    ) {
      success
      errorMessage
      errorData
    }
  }
`;
