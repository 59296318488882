import { State } from "../types";

interface Payload {
  isEmpty: boolean;
}

export default function setIsEmpty(state: State, payload: Payload): State {
  const { isEmpty } = payload;

  return {
    ...state,
    isEmpty,
  };
}
