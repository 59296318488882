import { useCallback } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import { ReducerType, ContextValue } from "../types";
/**
 * Gets the totes that are currently assigned to this station
 */
export default function useGetTotes(context: ContextValue): ContextValue {
  const { stateRef, dispatch } = context;

  const onCompleted = useCallback(
    function onCompleted(data) {
      const { totes } = data.intakeStation;
      dispatch({
        type: ReducerType.updateTotes,
        payload: {
          totes,
        },
      });
    },
    [dispatch]
  );

  const [query] = useLazyQuery(GQL, {
    fetchPolicy: "network-only",
    onCompleted,
  });

  context.getTotes = useCallback(() => {
    const { stationId } = stateRef.current;

    const variables = {
      id: stationId,
    };
    return query({ variables });
  }, [query, stateRef]);

  return context;
}

const GQL = gql`
  query IntakeStation($id: String!) {
    intakeStation(id: $id) {
      totes {
        id
        name
      }
    }
  }
`;
