import { State } from "../types";

/**
 * overrides errors values
 */
export default function setErrors(state: State, payload): State {
  const { errors } = payload;
  return {
    ...state,
    errors,
  };
}
