import { ContextValue } from "../types";

import useStartBoxReturn from "./useStartBoxReturn";
import useCreateReturnedBook from "./useCreateReturnedBook";
import useToggleDamage from "./useToggleDamage";
import useGetDamageOptions from "./useGetDamageOptions";
import useCloseBoxReturn from "./useCloseBoxReturn";
import useCloseView from "./useCloseView";
import useSelectBook from "./useSelectBook";
import useSetErrors from "./useSetErrors";
import useSetNotes from "./useSetNotes";
import useSetDamageCategory from "./useSetDamageCategory";
import useShowTotes from "./useShowTotes";
import useRemoveReturnedBook from "./useRemoveReturnedBook";
import useIntakeOptions from "./useIntakeOptions";
import useReverseBoxReturn from "./useReverseBoxReturn";
import useCancelBoxReturn from "./useCancelBoxReturn";

const hooks: { (context: ContextValue): ContextValue }[] = [
  useSetErrors,
  useCloseView,
  useStartBoxReturn,
  useCreateReturnedBook,
  useToggleDamage,
  useGetDamageOptions,
  useCloseBoxReturn,
  useSelectBook,
  useSetNotes,
  useSetDamageCategory,
  useShowTotes,
  useRemoveReturnedBook,
  useIntakeOptions,
  useReverseBoxReturn,
  useCancelBoxReturn,
];

export default hooks;
